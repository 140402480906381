import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Book } from "../assets/svg/book-alt.svg";
import { ReactComponent as BookFill } from "../assets/svg/book-alt-fill.svg";
import { ReactComponent as House } from "../assets/svg/home.svg";
import { ReactComponent as HouseFill } from "../assets/svg/home-fill.svg";
import { ReactComponent as Cross } from "../assets/svg/cross.svg";
import { ReactComponent as CrossFill } from "../assets/svg/cross-fill.svg";
import { ReactComponent as Fire } from "../assets/svg/fire.svg";
import { ReactComponent as FireFill } from "../assets/svg/fire-fill.svg";
import COLORS from "../assets/colors";

const Navbar: React.FC = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);
  const [color, setColor] = useState<string>(COLORS.blue);
  useEffect(() => {
    // Met à jour le chemin lorsque l'URL change
    setCurrentPath(location.pathname);
    console.log(location.pathname);
    switch (location.pathname) {
      case "/":
        setColor(COLORS.blue);

        break;
      case "/parole":
        setColor(COLORS.green);
        break;
      case "/eucharistie":
        setColor(COLORS.yellow);
        break;
      case "/envoi":
        setColor(COLORS.red);
        break;
      default:
        setColor(COLORS.blue);
    }
  }, [location]);

  const styles = {
    backgroundGlow: {
      position: "absolute" as "absolute",
      width: 50,
      height: 50,
      borderRadius: "50%",
      // left: "50%",
      // transform: "translateX(-50%)",
      top: "-10%",
      transform: "translateY(50%)",
      // backgroundColor: `radial-gradient( var(${color}),var(${color2}) )`,
      backgroundColor: color,
      zIndex: -1,
      filter: "blur(20px)",
    },
  };
  return (
    <>
      <nav
        className="navbar"
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 100,

          backdropFilter: "blur(5px)",
          // backgroundColor: "rgba(19, 25, 35, 0.95)",
          // height: 80,
        }}
      >
        <div
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            // paddingTop: 15,
            // paddingBottom: 5,
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100% - 40px)",
          }}
        >
          <NavLink
            to="/"
            // className={({ isActive }) => (isActive ? "active" : "")}
            style={{
              textDecoration: "none",
              userSelect: "none",
              outline: "none",
            }}
            className={"navlink"}
          >
            <div
              style={{
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/" ? (
                <House
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <HouseFill
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -35 }}>Accueil</p>
              {currentPath === "/" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/parole"
            // className={({ isActive }) => (isActive ? "active" : "")}
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/parole" ? (
                <BookFill
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Book
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -35 }}>Parole</p>
              {currentPath === "/parole" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/eucharistie"
            // className={({ isActive }) => (isActive ? "active" : "")}
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/eucharistie" ? (
                <CrossFill
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Cross
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -35 }}>Eucharistie</p>
              {currentPath === "/eucharistie" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/envoi"
            // className={({ isActive }) => (isActive ? "active" : "")}
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/envoi" ? (
                <FireFill
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Fire
                  height={30}
                  width={30}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -35 }}>Envoi</p>
              {currentPath === "/envoi" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
