// type COLORS = {
//   white: string;
//   background: string;
//   navbar: string;
//   blue: string;
//   green: string;
//   yellow: string;
//   red: string;
// };

const COLORS = {
  white: "#ffffff",
  background: "#19202b",
  navbar: "rgb(19, 25, 35, 0.95)",
  blue: "#215AF5",
  green: "#10ba58",
  yellow: "#ecc30b",
  red: "#DB0A16",
};
export default COLORS;
