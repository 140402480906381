import React from "react";
import Chant from "../assets/components/chant";
import COLORS from "../assets/colors";
import SomeText from "../assets/components/someText";

const Eucharistie: React.FC<{ messe: any; active: any }> = ({
  messe,
  active,
}) => {
  return (
    <div
      style={{
        // height: "200vh",
        // paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      {/* <h1>Messe du premier dimanche de l'Avent</h1>
      <h2>30 novembre 2024 - S. André</h2> */}
      {/* <Chant glow={false} color={COLORS.yellow} /> */}
      <Chant
        glow={active[0].moment === "offertoire" ? true : false}
        color={COLORS.yellow}
        couplets={messe.offertoire.couplets}
        refrain={messe.offertoire.refrain}
        titre={messe.offertoire.titre}
      />
      <Chant
        glow={active[0].moment === "sanctus" ? true : false}
        color={COLORS.yellow}
        couplets={messe.sanctus.couplets}
        refrain={messe.sanctus.refrain}
        titre={messe.sanctus.titre}
      />
      <Chant
        glow={active[0].moment === "notrePere" ? true : false}
        color={COLORS.yellow}
        refrain={[messe.notrePere.intro]}
        titre={messe.notrePere.titre}
        couplets={[messe.notrePere.texte]}
      />
      <Chant
        glow={active[0].moment === "agnusDei" ? true : false}
        color={COLORS.yellow}
        couplets={messe.agnusDei.couplets}
        refrain={messe.agnusDei.refrain}
        titre={messe.agnusDei.titre}
      />
      <SomeText />
      <Chant
        glow={active[0].moment === "communion" ? true : false}
        color={COLORS.yellow}
        couplets={messe.communion.couplets}
        refrain={messe.communion.refrain}
        titre={messe.communion.titre}
      />
      {/* <Chant glow={true} color={COLORS.yellow} /> */}
    </div>
  );
};

export default Eucharistie;
