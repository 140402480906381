import React from "react";

const Refrain: React.FC<{ refrain: string[] }> = ({ refrain }) => {
  const styles = {
    main: {
      // width: "100%",
      margin: "auto",
      padding: "0px 20px 20px 20px",
      fontWeight: 900,
    },
  };

  return (
    <div style={styles.main} className="couplet">
      {refrain.map((item: string, index: number) => (
        <p key={index}>{item}</p>
      ))}
      {/* <p>R. Préparez, à travers le désert,</p>
      <p>Les chemins du Seigneur.</p>
      <p>Écoutez, veillez, ouvrez vos cœurs,</p>
      <p>Car Il vient, le Sauveur.</p> */}
    </div>
  );
};

export default Refrain;
