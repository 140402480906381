import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./pages/navbar";
import Accueil from "./pages/Accueil";
import Parole from "./pages/Parole";
import Eucharistie from "./pages/Eucharistie";
import Envoi from "./pages/Envoi";
import { supabase } from "./supabaseClient";
import ScrollToTop from "./assets/components/scrollToTop";
import { Sheet } from "react-modal-sheet";
import COLORS from "./assets/colors";
import { ReactComponent as Close } from "../src/assets/svg/close.svg"; // Import du fichier SVG

const App: React.FC = () => {
  const [data, setData] = useState<any>();
  const [active, setActive] = useState<any>();

  const [loading, setLoading] = useState(true);

  const [isOpenSheet, setOpenSheet] = useState(
    (active && active[0]?.modal_sheet) || false
  );
  const closeModal = () => setOpenSheet(false);

  useEffect(() => {
    console.log((active && active[0]?.modal_sheet) || false);
    setOpenSheet((active && active[0]?.modal_sheet) || false);
  }, [active]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: items, error } = await supabase
        .from("messe")
        .select(
          "*, chantEntree:chantEntree(*), premiereLecture:premiereLecture(*), prierePenitentielle:prierePenitentielle(*), kyrie:kyrie(*), psaume:psaume(*), deuxiemeLecture:deuxiemeLecture(*), evangile:evangile(*), credo:credo(*), priereUniverselle:priereUniverselle(*), offertoire:offertoire(*), sanctus:sanctus(*), notrePere:notrePere(*) , agnusDei:agnusDei(*) ,communion:communion(*), sortie:sortie(*)"
        )
        .eq("id", "1")
        .single();

      if (error) {
        console.error("Error fetching data:", error);
      } else {
        setData(items);
      }
      const { data: items2, error: error2 } = await supabase
        .from("currentmoment")
        .select("*");
      if (error2) {
        console.error("Error fetching data:", error2);
      } else {
        setActive(items2);
      }

      setLoading(false);
    };

    fetchData();

    // Écouter les changements en temps réel sur la table "messe"
    const channel = supabase
      .channel("messe")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "messe",
          filter: `id=eq.1`,
        },
        fetchData
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "chant",
        },
        fetchData
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "texte",
        },
        fetchData
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "currentmoment",
        },
        fetchData
      )
      .subscribe();

    // Nettoyer l'abonnement à la fin
    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);
  if (loading) return <div>Loading...</div>;

  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Accueil messe={data} active={active} />} />
          <Route
            path="/parole"
            element={<Parole messe={data} active={active} />}
          />
          <Route
            path="/eucharistie"
            element={<Eucharistie messe={data} active={active} />}
          />
          <Route
            path="/envoi"
            element={<Envoi messe={data} active={active} />}
          />
        </Routes>
        <Sheet
          isOpen={isOpenSheet}
          onClose={() => closeModal()}
          // disableDrag={/^((?!chrome|android).)*safari/i.test(
          //   navigator.userAgent
          // )}
          snapPoints={[620]}
          disableDrag={/^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          )}
        >
          <Sheet.Container
            style={{
              width: "97vw",
              margin: "auto",
              left: "50%",
              translateX: "-50%",
              borderRadius: "30px 30px 0 0",
              overflow: "hidden",
              background: COLORS.background,
            }}
          >
            <Sheet.Header />
            <button
              className="close-button"
              onClick={closeModal}
              style={{
                width: "50px",
                height: "50px",
                zIndex: 100,
                borderRadius: "50%",
                position: "absolute",
                top: "20px",
                right: "20px",
                border: "none",
                background: COLORS.navbar,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Close width="24" height="24" fill="#ffffff" />
            </button>
            <Sheet.Content>
              <Sheet.Scroller draggableAt="both">
                <div
                  style={{ padding: 30, paddingTop: 0, textAlign: "center" }}
                  className="more"
                >
                  <h1>Bonjour !</h1>
                  <h3>
                    Vous êtes en avance !
                    <br />
                    Vous souhaitez écouter les chants pour les apprendre ?
                  </h3>
                  <br />
                  <h3>Chant d'entrée</h3>
                  <a
                    href="https://www.youtube.com/watch?v=uMHOPVs5eTI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Préparez à travers le désert
                  </a>
                  <br />
                  <br />
                  <h3>Prière universelle</h3>
                  <a
                    href="https://www.youtube.com/watch?v=p2U_BQdrb6w&list=PLj-qqeSVBHUai6cpPg4lEINv5SdGsat_C&index=49"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fiez vous en Lui
                  </a>
                  <br />
                  <br />
                  <h3>Offertoire</h3>
                  <a
                    href="https://www.youtube.com/watch?v=DEhEzyMHejs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jésus le Christ
                  </a>
                  <br />
                  <br />
                  <h3>Communion</h3>
                  <a
                    href="https://www.youtube.com/watch?v=ZQ75_-EJ-Jw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Recevez le Christ
                  </a>
                  <br />
                  <br />
                  <h3>Envoi</h3>
                  <a
                    href="https://www.youtube.com/watch?v=puSyyXv7-QU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Le Seigneur est ma lumière et mon salut
                  </a>
                  <br />
                  <br />
                  <br />
                  <h3>
                    Vous êtes musicien ? <br />
                    <a
                      href="https://drive.proton.me/urls/04HQ85SG00#uOS0vUTT2fAc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Voici les partitions !
                    </a>{" "}
                  </h3>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(10px)",
            }}
          />
        </Sheet>
      </div>
    </Router>
  );
};

export default App;
