import React, { useEffect, useRef } from "react";
import anime from "animejs";
import COLORS from "../colors";
import Couplet from "./couplet";
import Refrain from "./refrain";
interface ChantProps {
  glow: boolean; // Ajoutez une prop pour contrôler l'effet de glow
  color: string;
  couplets: string[][] | null;
  refrain: string[] | null;
  titre: string;
}

const Chant: React.FC<ChantProps> = ({
  glow,
  color,
  couplets,
  refrain,
  titre,
}) => {
  // Ajoutez votre logique de composant ici

  const styles = {
    title: {
      color: COLORS.white,
      backgroundColor: color,
      minWidth: "65%",
      maxWidth: "80%",
      borderRadius: "15px",
      position: "relative" as "relative",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },
    main: {
      width: "95%",
      margin: "auto",
      marginTop: 50,
      marginBottom: 50,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: COLORS.white, // Utilisation de la propriété non abrégée
      borderRadius: 15,
    },
    glow: {
      width: "95%",
      margin: "auto",
      marginTop: 50,
      marginBottom: 50,

      borderWidth: 3,
      borderStyle: "solid",
      borderColor: color, // Utilisation de la propriété non abrégée
      borderRadius: 15,
      boxShadow: `0 0 5px ${color}, inset 0 0 5px ${color}`,
    },
  };

  const mainRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (glow && mainRef.current) {
      anime({
        targets: mainRef.current,
        boxShadow: [
          `0 0 5px ${color}, inset 0 0 5px ${color}`,
          `0 0 6px ${color}, inset 0 0 5px ${color}`,
          `0 0 7px ${color}, inset 0 0 5px ${color}`,
          `0 0 8px ${color}, inset 0 0 5px ${color}`,
          `0 0 9px ${color}, inset 0 0 6px ${color}`,
          `0 0 10px ${color}, inset 0 0 6px ${color}`,
          `0 0 11px ${color}, inset 0 0 6px ${color}`,
          `0 0 12px ${color}, inset 0 0 6px ${color}`,
          `0 0 13px ${color}, inset 0 0 7px ${color}`,
          `0 0 14px ${color}, inset 0 0 7px ${color}`,
          `0 0 15px ${color}, inset 0 0 7px ${color}`,
          `0 0 16px ${color}, inset 0 0 7px ${color}`,
          `0 0 17px ${color}, inset 0 0 8px ${color}`,
          `0 0 18px ${color}, inset 0 0 8px ${color}`,
          `0 0 19px ${color}, inset 0 0 8px ${color}`,
          `0 0 20px ${color}, inset 0 0 8px ${color}`,
          `0 0 21px ${color}, inset 0 0 9px ${color}`,
          `0 0 22px ${color}, inset 0 0 9px ${color}`,
          `0 0 23px ${color}, inset 0 0 9px ${color}`,
          `0 0 24px ${color}, inset 0 0 9px ${color}`,
          `0 0 25px ${color}, inset 0 0 9px ${color}`,
          `0 0 26px ${color}, inset 0 0 9px ${color}`,
          `0 0 27px ${color}, inset 0 0 9px ${color}`,
          `0 0 28px ${color}, inset 0 0 9px ${color}`,
          `0 0 29px ${color}, inset 0 0 9px ${color}`,
          `0 0 30px ${color}, inset 0 0 9px ${color}`,
        ],
        duration: 1500,
        direction: "alternate",
        easing: "easeOutInCubic",
        delay: 800,
        loop: true,
      });
    }
  }, [glow]);

  return (
    <div
      ref={mainRef}
      className={"chant"}
      style={glow ? styles.glow : styles.main}
    >
      <div style={styles.title}>
        <h3 style={{ padding: "10px 10px", fontWeight: 900 }}>{titre}</h3>
      </div>

      {refrain && <Refrain refrain={refrain} />}

      {couplets &&
        couplets.map((text: string[], index: React.Key | null | undefined) => (
          <Couplet key={index} text={text} />
        ))}
    </div>
  );
};

export default Chant;
