import React, { useEffect, useState } from "react";
import Chant from "../assets/components/chant";
import COLORS from "../assets/colors";
import SomeText from "../assets/components/someText";
import { supabase } from "../supabaseClient";
import { Sheet } from "react-modal-sheet";

const Accueil: React.FC<{ messe: any; active: any }> = ({ messe, active }) => {
  // console.log(active[0].moment);
  // const [isOpenSheet, setOpenSheet] = useState(active[0].modal_sheet);
  // const closeModal = () => setOpenSheet(false);

  return (
    <div
      style={{
        // height: "200vh",
        paddingTop: "20px",
        paddingBottom: "50px",
      }}
    >
      <h1>{messe.titre}</h1>
      <h2>
        {new Date(messe.date).toLocaleDateString("fr-FR", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </h2>
      <Chant
        glow={active[0].moment === "chantEntree" ? true : false}
        color={COLORS.blue}
        couplets={messe.chantEntree.couplets}
        refrain={messe.chantEntree.refrain}
        titre={messe.chantEntree.titre}
      />
      <Chant
        glow={active[0].moment === "prierePenitentielle" ? true : false}
        color={COLORS.blue}
        couplets={[messe.prierePenitentielle.texte]}
        refrain={null}
        titre={messe.prierePenitentielle.titre}
      />
      <Chant
        glow={active[0].moment === "kyrie" ? true : false}
        color={COLORS.blue}
        couplets={messe.kyrie.couplets}
        refrain={messe.kyrie.refrain}
        titre={messe.kyrie.titre}
      />

      {/* <Sheet
        isOpen={isOpenSheet}
        onClose={() => closeModal()}
        disableDrag={/^((?!chrome|android).)*safari/i.test(navigator.userAgent)}
        snapPoints={[620]}
      >
        <Sheet.Container
          style={{
            width: "98vw",
            margin: "auto",
            left: "50%",
            translateX: "-50%",
            borderRadius: "30px 30px 0 0",
            overflow: "hidden",
            background: COLORS.background,
          }}
        >
          <Sheet.Header style={{ height: 0 }} />

          <Sheet.Content>
            <Sheet.Scroller draggableAt="both">
              <div
                style={{ padding: 30, textAlign: "center" }}
                className="more"
              >
                <h1>Bonjour !</h1>
                <h3>
                  Vous êtes en avance !
                  <br />
                  Vous souhaitez écouter les chants pour les apprendre ?
                </h3>
                <br />
                <h3>Chant d'entrée</h3>
                <a
                  href="https://www.youtube.com/watch?v=uMHOPVs5eTI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Préparez à travers le désert
                </a>
                <br />
                <br />
                <h3>Prière universelle</h3>
                <a
                  href="https://www.youtube.com/watch?v=p2U_BQdrb6w&list=PLj-qqeSVBHUai6cpPg4lEINv5SdGsat_C&index=49"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fiez vous en Lui
                </a>
                <br />
                <br />
                <h3>Offertoire</h3>
                <a
                  href="https://www.youtube.com/watch?v=DEhEzyMHejs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Jésus le Christ
                </a>
                <br />
                <br />
                <h3>Communion</h3>
                <a
                  href="https://www.youtube.com/watch?v=ZQ75_-EJ-Jw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recevez le Christ
                </a>
                <br />
                <br />
                <h3>Envoi</h3>
                <a
                  href="https://www.youtube.com/watch?v=puSyyXv7-QU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le Seigneur est ma lumière et mon salut
                </a>
                <br />
                <br />
                <br />
                <h3>
                  Vous êtes musicien ? <br />
                  <a
                    href="https://drive.proton.me/urls/04HQ85SG00#uOS0vUTT2fAc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Voici les partitions !
                  </a>{" "}
                </h3>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        />
      </Sheet> */}
    </div>
  );
};

export default Accueil;
