import React from "react";

const Couplet: React.FC<{ text: string[] }> = ({ text }) => {
  const styles = {
    main: {
      // width: "100%",
      margin: "auto",
      padding: "0px 20px 20px 20px",
    },
  };

  return (
    <div style={styles.main} className="couplet">
      {text.map((item: string, index: number) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
};

export default Couplet;
