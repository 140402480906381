import React from "react";
import Chant from "../assets/components/chant";
import COLORS from "../assets/colors";

const Parole: React.FC<{ messe: any; active: any }> = ({ messe, active }) => {
  console.log(messe.psaume);
  return (
    <div
      style={{
        // height: "200vh",
        // paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      {/* <h1>Messe du premier dimanche de l'Avent</h1>
      <h2>30 novembre 2024 - S. André</h2> */}

      <Chant
        glow={active[0].moment === "premiereLecture" ? true : false}
        color={COLORS.green}
        refrain={[messe.premiereLecture.intro]}
        titre={messe.premiereLecture.titre}
        couplets={[messe.premiereLecture.texte]}
      />

      <Chant
        glow={active[0].moment === "psaume" ? true : false}
        color={COLORS.green}
        refrain={messe.psaume.refrain}
        titre={messe.psaume.titre}
        couplets={messe.psaume.couplets}
      />
      {/* <Chant glow={false} color={COLORS.green} /> */}
      <Chant
        glow={active[0].moment === "deuxiemeLecture" ? true : false}
        color={COLORS.green}
        refrain={[messe.deuxiemeLecture.intro]}
        titre={messe.deuxiemeLecture.titre}
        couplets={[messe.deuxiemeLecture.texte]}
      />
      <Chant
        glow={active[0].moment === "evangile" ? true : false}
        color={COLORS.green}
        refrain={[messe.evangile.intro]}
        titre={messe.evangile.titre}
        couplets={[messe.evangile.texte]}
      />
      <Chant
        glow={active[0].moment === "credo" ? true : false}
        color={COLORS.green}
        couplets={[messe.credo.texte]}
        refrain={null}
        titre={messe.credo.titre}
      />
      <Chant
        glow={active[0].moment === "priereUniverselle" ? true : false}
        color={COLORS.green}
        couplets={null}
        refrain={messe.priereUniverselle.refrain}
        titre={messe.priereUniverselle.titre}
      />
    </div>
  );
};

export default Parole;
